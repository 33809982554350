import React from "react";
import {Tooltip} from "react-tooltip";
import {toast} from "react-toastify";
import {Badge} from "../ui";
import {BadgeType} from "../ui/Badge";
import {
  ITokenDict,
  NetworkCurrencyEnum,
  NetworkType,
  SelectedCurrencyType,
  StatusTxEnum
} from "../../pages/ConsolidationTool/types";
import {TableVirtuoso} from "react-virtuoso";
import {TokenInfo} from "../TokenInfo";

interface PropsTable {
  linkForTxScan: string,
  networkCurrency: typeof NetworkCurrencyEnum[NetworkType],
  isTransactionInfo: boolean,
  listInfo: TransactionInfo[] | CheckInfo [],
  selectedCurrencies: Set<SelectedCurrencyType>,
  tokenDict: ITokenDict
}

export interface CheckInfo {
  privateKey: string,
  address: string,
  balance: string,
  tokenBalances: Map<SelectedCurrencyType, string>
  feeDataByCurrency: Map<SelectedCurrencyType, string>,
  status: StatusTxEnum,
  txHashDataByCurrency: undefined
}

export interface TransactionInfo extends Omit<CheckInfo, 'txHashDataByCurrency'> {
  txHashDataByCurrency: Map<SelectedCurrencyType, string> | undefined
}

const statusAdapter = function (status: TransactionInfo['status']) {
  const statusType: Record<StatusTxEnum, BadgeType> = {
    [StatusTxEnum.SUCCESS]: 'success',
    [StatusTxEnum.ERROR]: 'danger',
    [StatusTxEnum.SKIP]: 'info',
  }

  return statusType?.[status] || 'info'
}

export function Table(props: PropsTable) {
  const {
    listInfo,
    linkForTxScan,
    isTransactionInfo,
    networkCurrency,
    selectedCurrencies,
    tokenDict
  } = props

  return (
    <TableVirtuoso
      style={{height: 400}}
      data={listInfo}
      components={{
        Table: ({style, ...props}) => <table {...props} style={{...style, width: "100%"}}/>,
        TableRow: ({style, ...props}) => <tr className="table-row" {...props} style={{...style}}/>,
      }}
      fixedHeaderContent={() => (
        <tr className="table-row table-row-heading">
          <td className="row-item">
            #
          </td>
          {
            isTransactionInfo ? (
              <td className="row-item">
                Status
              </td>
            ) : null
          }
          <td className="row-item">
            PK
          </td>
          <td className="row-item">
            Address
          </td>
          {
            !selectedCurrencies.has(networkCurrency) && Array.from(selectedCurrencies).map((currency, index) => (
              <td className="row-item" id={`token-info-tooltip-${currency}`} key={"balance" + index}>
                Balance ({currency})

                <TokenInfo
                  key={"tooltip" + index}
                  currency={currency}
                  dataToken={tokenDict[currency] || null}
                />
              </td>
            ))
          }
          <td className="row-item">
            Balance ({networkCurrency})
          </td>
          <td className="row-item">
            Fee min ({networkCurrency})
          </td>
        </tr>
      )}
      itemContent={(index, item) => {
        const cutPrivateKey = item.privateKey?.slice(0, 4) + '...' + item.privateKey?.slice(-4)
        const cutAccount = item.address?.slice(0, 4) + '...' + item.address?.slice(-4)
        let feeSumInBaseCurrency: number = 0
        item.feeDataByCurrency.forEach((fee) => feeSumInBaseCurrency += +fee)

        return (
          <>
            <td className="row-item">
              {index}
            </td>
            {isTransactionInfo ? (
              <td className="row-item">
                <Badge type={statusAdapter(item.status)}>
                  {item.status}
                </Badge>
              </td>
            ) : null}
            <td className="row-item">
              <div
                className="cursor-pointer hover:opacity-70 transition-all"
                onClick={() => {
                  navigator.clipboard.writeText(item.privateKey)
                  toast.success('Copied: ' + item.privateKey)
                }}
              >
                <a className="cursor-pointer" id={'privateKey-' + item.privateKey}>{cutPrivateKey}</a> <i
                className="icon-copy"/>
              </div>
              <Tooltip
                anchorSelect={'#privateKey-' + item.privateKey}
                content={item.privateKey}
              />
            </td>
            <td className="row-item">
              <div
                className="cursor-pointer hover:opacity-70 transition-all"
                onClick={() => {
                  navigator.clipboard.writeText(item.address)
                  toast.success('Copied: ' + item.address)
                }}
              >
                <a className="cursor-pointer" id={'address-' + item.address}>{cutAccount}</a> <i
                className="icon-copy"/>
              </div>
              <Tooltip
                anchorSelect={'#address-' + item.address}
                content={item.address}
              />
            </td>
            {!selectedCurrencies.has(networkCurrency) && Array.from(selectedCurrencies).map((currency, index) => (
              <td className="row-item" key={index}>
                <span>{item.tokenBalances.get(currency) || '0'}</span>
                {
                  item.txHashDataByCurrency && item.txHashDataByCurrency.has(currency) ?
                    <a href={linkForTxScan + item.txHashDataByCurrency.get(currency)} target="_blank"
                       className="text-blue-700">txHash</a> : ''
                }
              </td>
            ))}
            <td className={`row-item${+item.balance < feeSumInBaseCurrency ? ' text-red-700' : ''}`}>
              <span>{item.balance.toString()}</span>
              {
                item.txHashDataByCurrency && item.txHashDataByCurrency.has(networkCurrency) ?
                  <a href={linkForTxScan + item.txHashDataByCurrency.get(networkCurrency)} target="_blank"
                     className="text-blue-700">txHash</a> : ''
              }
            </td>
            <td className="row-item">{feeSumInBaseCurrency.toString()}</td>
          </>
        )
      }}
    />
  )
}